<template>
  <div class="w-full h-full flex flex-col p-2">
    <div class="w-full h-52 flex flex-row space-x-2">
      <div class="w-1/2 flex flex-col">
        <div class="text-gray-600 py-4 px-2">原工资表</div>
        <upload
          @fileSelected="(files) => fileSelected(files, 'source')"
          color="blue"
        />
        <div v-for="f in srcFiles" :key="f">{{ f }}</div>
      </div>

      <div class="w-1/2 flex flex-col">
        <div class="text-gray-600 py-4 px-2">税务工资表</div>
        <upload
          @fileSelected="(files) => fileSelected(files, 'target')"
          color="blue"
        />
        <div v-for="f in tgtFiles" :key="f">{{ f }}</div>
      </div>
    </div>
    <div
      @click="transform"
      class="w-full my-2 cursor-pointer text-center bg-blue-500 py-2 rounded text-gray-100 shadow-sm"
    >
      转换
    </div>
    <div v-if="resultXlsx">
      <a class="text-blue-500" :href="resultXlsx">点击下载</a>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import Upload from "@/components/Upload.vue";
import { HELPER_URL } from "@/config.js";

export default defineComponent({
  name: "TaxWage",
  components: { Upload },
  setup() {
    const store = useStore();
    const srcFiles = ref([]);
    const tgtFiles = ref([]);
    const resultXlsx = ref(null);
    const fileSelected = (files, category) => {
      store.dispatch("helper/upload", files).then((res) => {
        if (category === "source") {
          srcFiles.value = srcFiles.value.concat(res.data);
        } else {
          tgtFiles.value = tgtFiles.value.concat(res.data);
        }
      });
    };

    const transform = () => {
      if (srcFiles.value.length < 1 || tgtFiles.value.length < 1) {
        ElNotification.error({ message: "原工资表和税务工资表都必须上传" });
        return;
      }
      resultXlsx.value = null;
      const payload = {
        func: "wage",
        pkg: "app.fj_tax.api",
        kwargs: {
          src: srcFiles.value[0],
          tgt: tgtFiles.value[0],
        },
      };
      store
        .dispatch("helper/run", payload)
        .then((res) => {
          ElNotification.success({ message: "转换成功" });
          resultXlsx.value = `${HELPER_URL}/file/${res.data.result_xlsx}`;
        })
        .catch((e) => ElNotification.error({ message: `转换失败，${e}` }));
    };

    return { fileSelected, srcFiles, tgtFiles, transform, resultXlsx };
  },
});
</script>
